// @ts-ignore should add the types for this library
import { CustomField } from '@asigloo/vue-dynamic-forms';
import { FormField, FORM_FIELD_PAGES } from './create-space';

function createCustomFormField(form: any, field: FormField, initialValues: any) {
  return {
    ...form,
    [field.name]: CustomField({
      value: initialValues[field.name],
    })
  };
}

export function createFormFields(page: number, initialValues: any) {
  return FORM_FIELD_PAGES[page].reduce((form, field) => createCustomFormField(form, field, initialValues), {});
}

export function validations(form: any, name: string) {
  return FORM_FIELD_PAGES
    .flat()
    .find(field => field.name === name)
    ?.validation(form, name.replace(/[A-Z]/g, letter => `_${  letter.toLowerCase()}`));
}

/**
 * Wrapper for @see FormField.isErrored()
 *
 * @param form - The current space form
 * @param name - The name of the field in `form`
 * @returns `true` if the field value has resulted in an error, `false` otherwise
 *
 */
export function fieldIsErrored(form: any, name: string) {
  return FORM_FIELD_PAGES
    .flat()
    .find(field => field.name === name)
    ?.isErrored(form, name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`));
}
